<template>

 <div class="min-h-screen flex flex-col items-center justify-center bg-space overflow-hidden p-6">
    <div id="stars-container"></div>
    <div class="w-full max-w-md space-y-8 bg-opacity-80 bg-gray-900 p-8 rounded-xl shadow-2xl relative z-10">
      <div v-if="!selectedProfile">
        <h2 class="mt-6 text-center text-2xl sm:text-3xl font-extrabold text-white mb-8">
          Chọn Nhà Du Hành
        </h2>
        <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-6">
          <div
            v-for="profile in profiles"
            :key="profile.id"
            @click="handleProfileSelect(profile)"
            class="profile-item"
            :ref="el => { if (el) profileRefs[profile.id] = el }"
          >
            <div class="aspect-square overflow-hidden rounded-md">
              <img
                :src="getAvatarUrl(profile.avatar_url)"
                :alt="profile.name"
                class="w-full h-full object-cover transition-transform duration-300 transform hover:scale-110"
              />
            </div>
            <span class="mt-2 text-white text-center block">{{ profile.name }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <h2 class="mt-6 text-center text-2xl sm:text-3xl font-extrabold text-white">
          Xin chào, {{ selectedProfile.name }}!
        </h2>
        <p class="mt-2 text-center text-sm text-gray-300">
          Sẵn sàng chinh phục những vì sao?
        </p>
        <form v-if="selectedProfile.role === 'admin'" class="mt-8 space-y-6" @submit.prevent="handleAdminLogin">
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="email-address" class="sr-only">Email</label>
              <input id="email-address" name="email" type="email" autocomplete="email" required
                     class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                     placeholder="Email" v-model="email">
            </div>
            <div>
              <label for="password" class="sr-only">Mật khẩu</label>
              <input id="password" name="password" type="password" autocomplete="current-password" required
                     class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                     placeholder="Mật khẩu" v-model="password">
            </div>
          </div>
          <div>
            <button type="submit" class="w-full py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300 transform hover:scale-105">
              Đăng nhập
            </button>
          </div>
        </form>
        <form v-else class="mt-8 space-y-6" @submit.prevent="handleChildLogin">
          <div class="pin-input-container">
            <input
              v-for="(digit, index) in 4"
              :key="index"
              type="tel"
              inputmode="numeric"
              pattern="[0-9]*"
              maxlength="1"
              :ref="el => { if (el) pinInputs[index] = el }"
              v-model="pinDigits[index]"
              @input="handlePinInput(index)"
              @keydown.delete="handlePinDelete(index)"
              class="pin-input"
            >
          </div>
          <div>
            <button type="submit" class="w-full py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300 transform hover:scale-105">
              Bắt đầu Hành Trình!
            </button>
          </div>
        </form>
      </div>
      <div v-if="errorMessage" class="mt-3 text-center text-sm text-red-500">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import gsap from 'gsap';

export default {
  name: 'LoginView',
  setup() {
    const supabase = inject('supabase');
    const router = useRouter();
    const store = useStore();
    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const profiles = ref([]);
    const selectedProfile = ref(null);
    const pinDigits = ref(['', '', '', '']);
    const pinInputs = ref([]);
    const profileRefs = ref({});

    const defaultAvatarUrl = 'https://example.com/default-avatar.png'; // Replace with your default avatar URL

    const fetchProfiles = async () => {
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('id, name, role, avatar_url');
        
        if (error) throw error;
        
        profiles.value = data.map(profile => ({
          ...profile,
          avatar_url: profile.avatar_url || defaultAvatarUrl
        }));
      } catch (error) {
        console.error('Error fetching profiles:', error);
        errorMessage.value = 'Không thể tải danh sách người dùng. Vui lòng thử lại sau.';
      }
    };

    const getAvatarUrl = (url) => {
      if (!url || url === defaultAvatarUrl) return defaultAvatarUrl;
      return `${url}/-/scale_crop/200x200/center/`;
    };

    const handleProfileSelect = (profile) => {
      gsap.to(profileRefs.value[profile.id], {
        scale: 1.1,
        duration: 0.3,
        onComplete: () => {
          selectedProfile.value = profile;
          gsap.to(profileRefs.value[profile.id], { scale: 1, duration: 0.2 });
        }
      });
    };

    const handleAdminLogin = async () => {
      try {
        errorMessage.value = '';
        const { data, error } = await supabase.auth.signInWithPassword({
          email: email.value,
          password: password.value,
        });
        if (error) throw error;
        await loginSuccess(data.user, selectedProfile.value);
      } catch (error) {
        console.error('Đăng nhập thất bại:', error);
        errorMessage.value = 'Đăng nhập thất bại. Vui lòng kiểm tra lại thông tin đăng nhập.';
      }
    };

    const handleChildLogin = async () => {
      try {
        errorMessage.value = '';
        const pin = pinDigits.value.join('');
        const { data, error } = await supabase.rpc('verify_child_pin', {
          p_profile_id: selectedProfile.value.id,
          p_pin: pin
        });

        if (error) throw error;
        if (!data) throw new Error('PIN không hợp lệ');

        await loginSuccess(null, selectedProfile.value);
      } catch (error) {
        console.error('Đăng nhập thất bại:', error);
        errorMessage.value = 'Mã PIN không đúng. Vui lòng thử lại.';
        pinDigits.value = ['', '', '', ''];
        pinInputs.value[0].focus();
      }
    };

    const handlePinInput = (index) => {
      if (pinDigits.value[index].length === 1 && index < 3) {
        pinInputs.value[index + 1].focus();
      }
    };

    const handlePinDelete = (index) => {
      if (pinDigits.value[index].length === 0 && index > 0) {
        pinInputs.value[index - 1].focus();
      }
    };

    const loginSuccess = async (user, profile) => {
      const sessionData = {
        id: profile.id,
        role: profile.role,
        name: profile.name,
        avatar_url: profile.avatar_url,
      };
      localStorage.setItem('user_session', JSON.stringify(sessionData));
      await store.dispatch('setUser', sessionData);

      if (profile.role === 'admin') {
        await router.push('/admin');
      } else {
        await router.push('/child');
      }
    };

    const checkExistingSession = () => {
      const sessionData = localStorage.getItem('user_session');
      if (sessionData) {
        const session = JSON.parse(sessionData);
        store.dispatch('setUser', session);
        if (session.role === 'admin') {
          router.push('/admin');
        } else {
          router.push('/child');
        }
      }
    };

    const createStars = () => {
      const starsContainer = document.getElementById('stars-container');
      const starCount = 200;

      for (let i = 0; i < starCount; i++) {
        const star = document.createElement('div');
        star.className = 'star';

        const size = Math.random() * 2;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;

        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;

        star.style.animationDelay = `${Math.random() * 3}s`;
        star.style.animationDuration = `${3 + Math.random() * 4}s`;

        starsContainer.appendChild(star);
      }
    };

    onMounted(() => {
      checkExistingSession();
      fetchProfiles();
      createStars();

      gsap.to('.stars', {
        backgroundPosition: '0 10000px',
        duration: 200,
        repeat: -1,
        ease: 'linear'
      });
    });

    return {
      email,
      password,
      errorMessage,
      profiles,
      selectedProfile,
      pinDigits,
      pinInputs,
      profileRefs,
      handleProfileSelect,
      handleAdminLogin,
      handleChildLogin,
      handlePinInput,
      handlePinDelete,
      getAvatarUrl,
    };
  }
};
</script>

<style scoped>
.bg-space {
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#stars-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.star {
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0;
  animation: twinkle 3s infinite alternate;
}

@keyframes twinkle {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.profile-item {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.profile-item:hover {
  transform: scale(1.05);
}

.pin-input-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.pin-input {
  width: 50px;
  height: 60px;
  font-size: 24px;
  text-align: center;
  border: 2px solid #4a5568;
  border-radius: 12px;
  background-color: #2d3748;
  color: white;
  outline: none;
}

.pin-input:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

@media (max-width: 640px) {
  .pin-input {
    width: 40px;
    height: 50px;
    font-size: 20px;
  }
}
</style>