import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createClient } from '@supabase/supabase-js'
import './assets/styles/main.css'  // Import Tailwind CSS
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

// Cấu hình Supabase trực tiếp
const supabaseUrl = 'https://oiaglhvfveguatjytexg.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9pYWdsaHZmdmVndWF0anl0ZXhnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDY5NzMwOTMsImV4cCI6MjAyMjU0OTA5M30.AQ-rVuHYawErn7Vmlzz9UU_pa2nKGAGAu53QUFQxB-c'

const supabase = createClient(supabaseUrl, supabaseAnonKey)

const app = createApp(App)

app.use(router)
app.use(store)
app.provide('supabase', supabase)

app.mount('#app')