import { ref, computed, onMounted, watch } from 'vue';
import { supabase } from '@/supabase';
import { useStore } from 'vuex';

export function useChildpoints() {
  const store = useStore();
  const user = ref(null);
  const deductions = ref([]);
  const totalPoints = ref(0);
  const avatarUrl = ref('');
  const isLoading = ref(false);
  const error = ref(null);

  // Hàm lấy thông tin người dùng từ Supabase
  const fetchUserData = async () => {
    const currentUser = store.state.user;
    if (currentUser) {
      user.value = currentUser;
      try {
        // Truy vấn thông tin người dùng từ bảng profiles
        const { data, error: profileError } = await supabase
          .from('profiles')
          .select('total_points, avatar_url, name')
          .eq('id', currentUser.id)
          .single();

        if (profileError) throw profileError;

        // Cập nhật thông tin người dùng
        totalPoints.value = data.total_points;
        avatarUrl.value = data.avatar_url;
        user.value = { ...currentUser, name: data.name };
      } catch (err) {
        console.error('Lỗi khi lấy thông tin người dùng:', err);
        error.value = 'Không thể lấy thông tin người dùng';
      }
    }
  };

  // Hàm lấy danh sách các lần bị trừ điểm từ Supabase
  const fetchDeductions = async () => {
    if (!user.value) return;
    
    isLoading.value = true;
    error.value = null;

    try {
      // Truy vấn danh sách trừ điểm từ bảng deductions
      const { data, error: deductionsError } = await supabase
        .from('deductions')
        .select('*')
        .eq('user_id', user.value.id)
        .order('deducted_at', { ascending: false });

      if (deductionsError) throw deductionsError;

      deductions.value = data;
    } catch (err) {
      console.error('Lỗi khi lấy danh sách trừ điểm:', err);
      error.value = 'Không thể lấy danh sách trừ điểm';
    } finally {
      isLoading.value = false;
    }
  };

  // Computed property để định dạng URL avatar
  const getFormattedAvatarUrl = computed(() => {
    if (avatarUrl.value) {
      if (!avatarUrl.value.includes('/preview')) {
        return `${avatarUrl.value}-/preview/300x300/-/format/auto/-/quality/smart_retina/-/progressive/yes/-/round_corner/20/`;
      }
      return avatarUrl.value;
    }
    return 'https://via.placeholder.com/300x300?text=Avatar';
  });

  // Hàm làm mới dữ liệu
  const refreshData = async () => {
    await fetchUserData();
    await fetchDeductions();
  };

  // Gọi refreshData khi component được mount
  onMounted(refreshData);

  // Theo dõi thay đổi của user trong store và làm mới dữ liệu
  watch(() => store.state.user, refreshData);

  // Trả về các giá trị và hàm cần thiết
  return {
    user,
    deductions,
    totalPoints,
    getFormattedAvatarUrl,
    fetchDeductions,
    isLoading,
    error,
    refreshData
  };
}