<template>
  <div class="mars-expedition" :class="{ 'mobile-tablet': isMobileOrTablet }">
    <div v-if="isMobileOrTablet" class="lottie-background" ref="lottieBackground"></div>
    <div class="content-container">
      <header class="mission-control">
        <div class="astronaut-info">
          <img :src="getFormattedAvatarUrl" :alt="user?.name || 'Phi hành gia'" class="astronaut-avatar" @click="openAvatarModal">
          <div class="astronaut-details">
            <h1 class="astronaut-name">{{ user?.name || 'Phi hành gia' }}</h1>
            <div id="oxygenBar">
              <div id="oxygenFill"></div>
              <div id="oxygenGlow"></div>
              <div id="oxygenText">
                <i class="fas fa-lungs"></i>
                Oxy: {{ totalPoints }}/{{ maxPoints }}
              </div>
            </div>
          </div>
        </div>
      </header>

      <section class="mars-tasks">
        <h2 class="section-title">Nhiệm vụ khám phá Sao Hỏa</h2>
        <div v-if="allTasksCompleted" class="completion-message">
          <p>Giỏi lắm nhà thám hiểm! Hãy chờ ngày mai khám phá và kiếm thêm oxy nhé!</p>
          <div class="fireworks"></div>
        </div>
        <div v-else>
          <div class="progress-bar">
            <div class="progress" :style="{ width: `${completionPercentage}%` }"></div>
          </div>
          <transition-group name="list" tag="div" class="task-grid">
            <div v-for="task in visibleTasks" :key="task.id" 
                 class="task-item" 
                 :class="{ 'completed': task.is_completed }">
              <div class="task-icon">
                <i :class="getTaskIcon(task.category)"></i>
              </div>
              <h3 class="task-name">{{ task.task_name }}</h3>
              <div class="task-reward">+{{ task.points }} Oxy</div>
              <div v-if="task.is_completed" class="completion-tick">✓</div>
              <button v-else
                      @click="completeTask(task.id)" 
                      class="complete-button" 
                      ref="completeButtons" 
                      :data-task-id="task.id">
                Thực hiện
              </button>
            </div>
          </transition-group>
          <div v-if="hiddenTaskCount > 0" class="hidden-tasks-message">
            Còn {{ hiddenTaskCount }} nhiệm vụ khác
          </div>
        </div>
      </section>

      <section class="mars-rewards">
        <h2 class="section-title">Trạm tiếp tế</h2>
        <div class="reward-grid">
          <div v-for="reward in visibleRewards" :key="reward.id" class="reward-item" :class="{ 'available': totalPoints >= reward.points_required }">
            <div class="reward-icon">
              <i :class="getRewardIcon(reward.category)"></i>
            </div>
            <h3 class="reward-name">{{ reward.reward_name }}</h3>
            <div class="reward-cost">
              <i class="fas fa-lungs"></i>
              <span>{{ reward.points_required }} Oxy</span>
            </div>
            <button @click="redeemReward(reward.id)" :disabled="totalPoints < reward.points_required" class="redeem-button" ref="redeemButtons" :data-reward-id="reward.id">
              Đổi lấy
            </button>
          </div>
          <div v-if="specialRewardUnlocked" class="special-reward-item">
            <div class="special-reward-icon">
              <i class="fas fa-gift"></i>
            </div>
            <h3 class="special-reward-name">Phần thưởng đặc biệt</h3>
            <button @click="claimSpecialReward" class="special-reward-button">
              Nhận ngay
            </button>
          </div>
        </div>
      </section>

      <section class="mars-discoveries">
        <h2 class="section-title">Nhật ký khám phá</h2>
        <ul class="discovery-list">
          <li v-for="item in rewardHistory" :key="item.id" class="discovery-item">
            <i :class="getRewardIcon(item.category)"></i>
            <div class="discovery-details">
              <span class="discovery-name">{{ item.reward_name }}</span>
              <span class="discovery-date">{{ formatDate(item.redeemed_at) }}</span>
            </div>
          </li>
        </ul>
      </section>
    </div>

    <div class="flag-container" ref="flagContainer"></div>
    <div class="new-location-modal" v-if="showNewLocation">
      <h2>Địa điểm mới đã mở khóa!</h2>
      <p>{{ newLocationInfo }}</p>
      <button @click="closeNewLocation">Đóng</button>
    </div>

    <transition name="modal-fade">
      <div class="avatar-modal" v-if="showAvatarModal">
        <h2>Tùy chỉnh phi hành gia</h2>
        <div class="avatar-options">
          <img v-for="avatar in avatarOptions" :key="avatar" :src="avatar" @click="selectAvatar(avatar)">
        </div>
        <input type="text" v-model="astronautName" placeholder="Nhập tên của bạn">
        <button @click="saveAvatarSettings">Lưu</button>
      </div>
    </transition>

    <button @click="quayVeVuTru" class="nut-quay-ve-vu-tru pulse-animation">
      <i class="fas fa-rocket"></i>
      QUAY VỀ VŨ TRỤ
    </button>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useChildTask } from '@/assets/js/useChildTask';
import { localStorageService } from '@/services/localStorageService';
import { audioService } from '@/assets/js/audioService';
import gsap from 'gsap';
import lottie from 'lottie-web';
import zonAnimation from '@/assets/json/zon7.json';
import confetti from 'confetti-js';

export default {
  name: 'ChildTaskView',
  setup() {
    const MAX_VISIBLE_TASKS = 20;
    const MAX_VISIBLE_REWARDS = 6;
    const SPECIAL_REWARD_POINTS = 100;
    
    const router = useRouter();
    // eslint-disable-next-line no-unused-vars
    const store = useStore();
    const {
      user,
      tasks,
      rewards,
      rewardHistory,
      totalPoints,
      completeTask: originalCompleteTask,
      redeemReward: originalRedeemReward,
      getFormattedAvatarUrl,
      fetchUserData,
      fetchTasks
    } = useChildTask();
    const selectedAvatar = ref('');
    const lottieBackground = ref(null);
    const flagContainer = ref(null);
    const showNewLocation = ref(false);
    const newLocationInfo = ref('');
    const showAvatarModal = ref(false);
    const astronautName = ref('');
    const avatarOptions = ref([
      '/path/to/avatar1.png',
      '/path/to/avatar2.png',
    ]);
    const specialRewardUnlocked = ref(false);
    const showTutorial = ref(true);
    const maxPoints = ref(100);

    const isMobileOrTablet = computed(() => window.innerWidth <= 1024);
    const visibleTasks = computed(() => tasks.value.slice(0, MAX_VISIBLE_TASKS));
    const hiddenTaskCount = computed(() => Math.max(tasks.value.length - MAX_VISIBLE_TASKS, 0));
    const visibleRewards = computed(() => rewards.value.slice(0, MAX_VISIBLE_REWARDS));
    const allTasksCompleted = computed(() => tasks.value.length > 0 && tasks.value.every(task => task.is_completed));
    const completionPercentage = computed(() => {
      if (tasks.value.length === 0) return 0;
      const completedTasks = tasks.value.filter(task => task.is_completed).length;
      return (completedTasks / tasks.value.length) * 100;
    });
    const oxygenPercentage = computed(() => (totalPoints.value / maxPoints.value) * 100);

    const getTaskIcon = (category) => {
      const icons = {
        'study': 'fas fa-book',
        'exercise': 'fas fa-running',
        'chores': 'fas fa-broom',
        'default': 'fas fa-tasks'
      };
      return icons[category] || icons.default;
    };

    const getRewardIcon = (category) => {
      const icons = {
        'toy': 'fas fa-gamepad',
        'food': 'fas fa-ice-cream',
        'activity': 'fas fa-ticket-alt',
        'default': 'fas fa-gift'
      };
      return icons[category] || icons.default;
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('vi-VN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    const initLottieBackground = () => {
      if (!lottieBackground.value || !isMobileOrTablet.value) return;

      let animation = null;

      const loadAnimation = () => {
        if (animation) {
          animation.destroy();
        }

        animation = lottie.loadAnimation({
          container: lottieBackground.value,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: zonAnimation
        });
      };

      const resizeBackground = () => {
        if (!animation || !animation.isLoaded) return;

        const containerAspectRatio = window.innerWidth / window.innerHeight;
        const animationAspectRatio = 1080 / 1920;

        try {
          if (containerAspectRatio > animationAspectRatio) {
            const scale = window.innerWidth / 1080;
            animation.resize();
            lottieBackground.value.style.width = `${window.innerWidth}px`;
            lottieBackground.value.style.height = `${1920 * scale}px`;
          } else {
            const scale = window.innerHeight / 1920;
            animation.resize();
            lottieBackground.value.style.width = `${1080 * scale}px`;
            lottieBackground.value.style.height = `${window.innerHeight}px`;
          }
        } catch (error) {
          console.error('Error resizing Lottie animation:', error);
        }
      };

      loadAnimation();

      animation.addEventListener('DOMLoaded', () => {
        resizeBackground();
      });

      const debouncedResize = debounce(resizeBackground, 250);
      window.addEventListener('resize', debouncedResize);

      return () => {
        window.removeEventListener('resize', debouncedResize);
        if (animation) {
          animation.destroy();
        }
      };
    };

    const debounce = (func, wait) => {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    };

    const checkNewLocation = () => {
      const locations = [
        { points: 100, info: 'Bạn đã khám phá Olympus Mons, ngọn núi cao nhất trong hệ mặt trời!' },
        { points: 250, info: 'Bạn đã tìm thấy Valles Marineris, hẻm núi dài nhất trên Sao Hỏa!' },
        { points: 500, info: 'Chúc mừng! Bạn đã đến được Cực Bắc của Sao Hỏa!' }
      ];
      
      const unlockedLocation = locations.find(loc => totalPoints.value >= loc.points);
      if (unlockedLocation) {
        newLocationInfo.value = unlockedLocation.info;
        showNewLocation.value = true;
      }
    };

    const closeNewLocation = () => {
      showNewLocation.value = false;
    };

    const initTasks = async () => {
      console.log('Initializing tasks');
      await fetchTasks();
      console.log('Tasks fetched, checking local storage');
      const savedTasks = localStorageService.getTasks();
      if (savedTasks) {
        console.log('Found saved tasks in local storage:', savedTasks);
        tasks.value = tasks.value.map(task => {
          const savedTask = savedTasks.find(st => st.id === task.id);
          return savedTask ? { ...task, is_completed: savedTask.is_completed } : task;
        });
        console.log('Updated tasks with local storage data:', tasks.value);
      } else {
        console.log('No saved tasks found in local storage');
      }
    };

    const initAudio = () => {
      audioService.init();
    };

    const completeTask = async (taskId) => {
      await originalCompleteTask(taskId);
      const taskElement = document.querySelector(`[data-task-id="${taskId}"]`);
      if (taskElement) {
        gsap.to(taskElement, {
          y: -20,
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            taskElement.style.display = 'none';
            checkNewLocation();
            triggerConfetti();
          }
        });
      }
      
      audioService.playSound('taskComplete');

      if (allTasksCompleted.value) {
        audioService.playSound('allTasksComplete');
      }

      localStorageService.saveTasks(tasks.value);
      updateOxygenBar();
    };

    const triggerConfetti = () => {
      const confettiSettings = { target: 'confetti-canvas' };
      const confettiInstance = new confetti.create(confettiSettings);
      confettiInstance.render();
    };

    const reloadData = async () => {
      console.log('Reloading data');
      await fetchUserData();
      await initTasks();
      console.log('Data reloaded');
      updateOxygenBar();
    };

    const redeemReward = async (rewardId) => {
      console.log('Redeeming reward:', rewardId);
      const reward = rewards.value.find(r => r.id === rewardId);
      if (!reward || totalPoints.value < reward.points_required) {
        console.log('Cannot redeem reward: insufficient points or reward not found');
        return;
      }

      await originalRedeemReward(rewardId);
      audioService.playSound('rewardClaimed');
      updateOxygenBar();
    };

    const quayVeVuTru = () => {
      router.push('/child');
    };

    const openAvatarModal = () => {
      showAvatarModal.value = true;
    };

    const selectAvatar = (avatar) => {
      selectedAvatar.value = avatar;
      console.log('Avatar đã được chọn:', avatar);
    };

    const saveAvatarSettings = () => {
      showAvatarModal.value = false;
      // Logic lưu cài đặt avatar và tên phi hành gia
    };

    const shareAchievements = () => {
      // Logic chia sẻ thành tích
    };

    const startTutorial = () => {
      showTutorial.value = false;
      // Logic bắt đầu hướng dẫn
    };

    const claimSpecialReward = () => {
      specialRewardUnlocked.value = false;
      // Logic nhận phần thưởng đặc biệt
    };

    const checkSpecialReward = () => {
      if (totalPoints.value >= SPECIAL_REWARD_POINTS) {
        specialRewardUnlocked.value = true;
      }
    };

    const updateOxygenBar = () => {
      const oxygenFill = document.getElementById('oxygenFill');
      const oxygenGlow = document.getElementById('oxygenGlow');
      if (oxygenFill && oxygenGlow) {
        gsap.to(oxygenFill, {
          width: `${oxygenPercentage.value}%`,
          duration: 1,
          ease: "power2.out"
        });
        gsap.to(oxygenGlow, {
          x: `${oxygenPercentage.value - 100}%`,
          duration: 1,
          ease: "power2.out"
        });
      }
    };

    onMounted(() => {
      initLottieBackground();
      reloadData();
      checkSpecialReward();
      updateOxygenBar();
      window.addEventListener('resize', () => {
        if (isMobileOrTablet.value) {
          initLottieBackground();
        }
      });

      document.body.addEventListener('touchstart', initAudio, { once: true });
      document.body.addEventListener('click', initAudio, { once: true });
    });

    watch(totalPoints, () => {
      checkSpecialReward();
      updateOxygenBar();
    });

    watch(tasks, (newTasks) => {
      console.log('Tasks changed, updating local storage');
      localStorageService.saveTasks(newTasks);
    }, { deep: true });

    return {
      user,
      tasks,
      rewards,
      rewardHistory,
      totalPoints,
      visibleTasks,
      hiddenTaskCount,
      visibleRewards,
      completeTask,
      redeemReward,
      getFormattedAvatarUrl,
      lottieBackground,
      flagContainer,
      showNewLocation,
      newLocationInfo,
      closeNewLocation,
      getTaskIcon,
      getRewardIcon,
      formatDate,
      isMobileOrTablet,
      allTasksCompleted,
      completionPercentage,
      initTasks,
      reloadData,
      quayVeVuTru,
      showAvatarModal,
      astronautName,
      avatarOptions,
      openAvatarModal,
      selectedAvatar,
      selectAvatar,
      saveAvatarSettings,
      shareAchievements,
      showTutorial,
      startTutorial,
      specialRewardUnlocked,
      claimSpecialReward,
      maxPoints,
      oxygenPercentage,
    };
  }
};
</script>
<style scoped>
@import '@/assets/styles/child-task.css';
</style>