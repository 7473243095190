<template>
  <div class="flex h-screen bg-gray-100">
    <!-- Sidebar -->
    <div :class="['bg-primary text-white w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform transition duration-200 ease-in-out', {'translate-x-0': sidebarOpen, '-translate-x-full': !sidebarOpen}, 'md:relative md:translate-x-0']">
      <a href="#" class="text-white flex items-center space-x-2 px-4">
        <i class="fas fa-rocket text-2xl"></i>
        <span class="text-2xl font-extrabold">Tiến Huy </span>
      </a>
      <nav>
        <router-link
          v-for="item in menuItems"
          :key="item.name"
          :to="item.href"
          class="block py-2.5 px-4 rounded transition duration-200 hover:bg-primary-dark hover:text-white"
        >
          {{ item.name }}
        </router-link>
      </nav>
    </div>

    <!-- Content -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Top bar -->
      <header class="flex justify-between items-center py-4 px-6 bg-white border-b-4 border-primary">
        <div class="flex items-center">
          <button @click="toggleSidebar" class="text-gray-500 focus:outline-none md:hidden">
            <i class="fas fa-bars"></i>
          </button>
        </div>
        <div class="flex items-center">
          <button @click="logout" class="flex items-center text-gray-700 hover:text-primary focus:outline-none">
            <span class="mr-2">{{ adminName }}</span>
            <i class="fas fa-user-circle text-2xl"></i>
          </button>
        </div>
      </header>

      <!-- Main content -->
      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
        <div class="container mx-auto px-6 py-8">
          <!-- Đây là nơi nội dung component sẽ thay đổi dựa trên router -->
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'AdminHomeView',
  setup() {
    const router = useRouter()
    const store = useStore()
    
    const sidebarOpen = ref(false)
    const adminName = ref('Admin')
    
    const menuItems = [
      { name: 'Dashboard', href: '/admin/dashboard' },
      { name: 'Quản lý nhiệm vụ', href: '/admin/tasks' },
      { name: 'Quản lý phần thưởng', href: '/admin/rewards' },
      { name: 'Theo dõi tiến độ', href: '/admin/progress' },
      { name: 'Trừ điểm', href: '/admin/deduct-points' },
    ]

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value
    }

    const logout = async () => {
      try {
        await store.dispatch('logout')
        router.push('/login')
      } catch (error) {
        console.error('Lỗi khi đăng xuất:', error)
      }
    }

    onMounted(() => {
      // Thêm code lấy dữ liệu cho dashboard nếu cần
    })

    return {
      sidebarOpen,
      adminName,
      menuItems,
      toggleSidebar,
      logout
    }
  }
}
</script>

<style scoped>
.bg-primary {
  background-color: #174c9a;
}
.text-primary {
  color: #174c9a;
}
.border-primary {
  border-color: #174c9a;
}
.hover\:bg-primary-dark:hover {
  background-color: #0d2f5e;
}
</style>
