<template>
  <div class="p-6 bg-white rounded-lg shadow-lg">
    <h3 class="text-2xl font-semibold mb-4">Quản lý Nhiệm vụ</h3>

    <!-- Form thêm nhiệm vụ mới -->
    <form @submit.prevent="addTask" class="mb-6">
      <div class="mb-4">
        <label class="block text-gray-700">Tên nhiệm vụ</label>
        <input v-model="newTask.task_name" type="text" class="mt-2 p-2 w-full border rounded" placeholder="Nhập tên nhiệm vụ" required>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700">Điểm số</label>
        <input v-model="newTask.points" type="number" class="mt-2 p-2 w-full border rounded" placeholder="Nhập điểm số" required>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700">Danh mục</label>
        <input v-model="newTask.category" type="text" class="mt-2 p-2 w-full border rounded" placeholder="Nhập danh mục" required>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700">Loại nhiệm vụ</label>
        <select v-model="newTask.repeat_type" class="mt-2 p-2 w-full border rounded" required>
          <option value="daily">Hàng ngày</option>
          <option value="weekly">Hàng tuần</option>
          <option value="bonus">Bonus</option>
        </select>
      </div>

      <button type="submit" class="bg-primary text-white px-4 py-2 rounded">Thêm nhiệm vụ</button>
    </form>

    <!-- Form gán nhiệm vụ cho người dùng -->
    <form @submit.prevent="assignTasksToUser" class="mb-6">
      <div class="mb-4">
        <label class="block text-gray-700">Chọn người dùng</label>
        <select v-model="selectedUserId" class="mt-2 p-2 w-full border rounded" required>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
        </select>
      </div>

      <button type="submit" class="bg-primary text-white px-4 py-2 rounded">Gán nhiệm vụ cho người dùng</button>
    </form>

    <!-- Cảnh báo thêm thành công hoặc thất bại -->
    <div v-if="successMessage" class="bg-green-200 text-green-800 p-2 rounded mb-4">
      {{ successMessage }}
    </div>
    <div v-if="errorMessage" class="bg-red-200 text-red-800 p-2 rounded mb-4">
      {{ errorMessage }}
    </div>

    <!-- Danh sách nhiệm vụ hiện tại -->
    <div class="mt-6">
      <h4 class="text-xl font-medium mb-4">Danh sách nhiệm vụ</h4>
      <table class="min-w-full bg-white">
        <thead class="bg-gray-200 text-gray-600">
          <tr>
            <th class="py-3 px-6 text-left">
              <input type="checkbox" @change="selectAllTasks($event)" />
            </th>
            <th class="py-3 px-6 text-left">Tên</th>
            <th class="py-3 px-6 text-left">Điểm</th>
            <th class="py-3 px-6 text-left">Danh mục</th>
            <th class="py-3 px-6 text-left">Loại nhiệm vụ</th>
            <th class="py-3 px-6 text-left">Được gán cho</th>
            <th class="py-3 px-6 text-center">Hành động</th>
          </tr>
        </thead>
        <tbody class="text-gray-600">
          <tr v-for="task in tasks" :key="task.id" class="border-b border-gray-200 hover:bg-gray-100">
            <td class="py-3 px-6">
              <input type="checkbox" :value="task.id" v-model="selectedTaskIds" />
            </td>
            <td class="py-3 px-6">{{ task.task_name }}</td>
            <td class="py-3 px-6">{{ task.points }}</td>
            <td class="py-3 px-6">{{ task.category }}</td>
            <td class="py-3 px-6">{{ task.repeat_type }}</td>
            <td class="py-3 px-6">{{ task.assigned_to }}</td>
            <td class="py-3 px-6 text-center">
              <button @click="deleteTask(task.id)" class="text-red-500 hover:text-red-700">
                <i class="fas fa-trash-alt"></i> Xóa
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '@/supabase'

export default {
  name: 'ManageTasks',
  setup() {
    const tasks = ref([])
    const users = ref([])
    const selectedTaskIds = ref([])
    const selectedUserId = ref('')
    const newTask = ref({
      task_name: '',
      points: '',
      category: '',
      repeat_type: 'daily' // Giá trị mặc định
    })
    const successMessage = ref('')
    const errorMessage = ref('')

    const fetchTasks = async () => {
      const { data, error } = await supabase
        .from('tasks')
        .select(`
          *,
          user_tasks (
            profiles (
              name
            )
          )
        `)
      
      if (error) {
        console.error('Error fetching tasks:', error)
      } else {
        tasks.value = data.map(task => {
          const assignedUser = task.user_tasks?.[0]?.profiles?.name || 'Chưa gán'
          return {
            ...task,
            assigned_to: assignedUser
          }
        }) || []
      }
    }

    const fetchUsers = async () => {
      const { data, error } = await supabase
        .from('profiles')
        .select('id, name')
      
      if (error) {
        console.error('Error fetching users:', error)
      } else {
        users.value = data || []
      }
    }

    const addTask = async () => {
      successMessage.value = ''
      errorMessage.value = ''
      
      try {
        const { error } = await supabase
          .from('tasks')
          .insert([{ 
            task_name: newTask.value.task_name, 
            points: newTask.value.points, 
            category: newTask.value.category,
            repeat_type: newTask.value.repeat_type 
          }])
        
        if (error) {
          errorMessage.value = 'Lỗi khi thêm nhiệm vụ: ' + error.message
          console.error('Error adding task:', error.message)
        } else {
          successMessage.value = 'Nhiệm vụ đã được thêm thành công!'
          fetchTasks() // Cập nhật danh sách nhiệm vụ sau khi thêm thành công
          newTask.value.task_name = ''
          newTask.value.points = ''
          newTask.value.category = ''
          newTask.value.repeat_type = 'daily'
        }
      } catch (error) {
        errorMessage.value = 'Đã xảy ra lỗi khi thêm nhiệm vụ.'
        console.error('Error adding task:', error)
      }
    }

    const assignTasksToUser = async () => {
      successMessage.value = ''
      errorMessage.value = ''

      if (selectedTaskIds.value.length === 0) {
        errorMessage.value = 'Vui lòng chọn ít nhất một nhiệm vụ để gán.'
        return
      }

      try {
        const { error } = await supabase
          .from('user_tasks')
          .insert(selectedTaskIds.value.map(taskId => ({
            user_id: selectedUserId.value,
            task_id: taskId,
            assigned_at: new Date()
          })))
        
        if (error) {
          errorMessage.value = 'Lỗi khi gán nhiệm vụ cho người dùng: ' + error.message
          console.error('Error assigning task to user:', error.message)
        } else {
          successMessage.value = 'Nhiệm vụ đã được gán thành công!'
          selectedTaskIds.value = [] // Reset selected tasks
          fetchTasks() // Cập nhật lại danh sách nhiệm vụ để hiển thị người dùng đã gán
        }
      } catch (error) {
        errorMessage.value = 'Đã xảy ra lỗi khi gán nhiệm vụ.'
        console.error('Error assigning task:', error)
      }
    }

    const selectAllTasks = (event) => {
      if (event.target.checked) {
        selectedTaskIds.value = tasks.value.map(task => task.id)
      } else {
        selectedTaskIds.value = []
      }
    }

    const deleteTask = async (taskId) => {
      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', taskId)
      
      if (error) {
        console.error('Error deleting task:', error)
      } else {
        fetchTasks() // Cập nhật danh sách nhiệm vụ sau khi xóa thành công
      }
    }

    onMounted(() => {
      fetchTasks()
      fetchUsers()
    })

    return {
      tasks,
      users,
      selectedTaskIds,
      selectedUserId,
      newTask,
      successMessage,
      errorMessage,
      addTask,
      assignTasksToUser,
      selectAllTasks,
      deleteTask
    }
  }
}
</script>

<style scoped>
.bg-primary {
  background-color: #174c9a;
}
</style>
