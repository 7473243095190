<template>
  <div class="childpoints-view" :class="{ 'mobile-tablet': isMobileOrTablet }">
    <div v-if="isMobileOrTablet" class="lottie-background" ref="lottieBackground"></div>
    <div class="content-container">
      <header class="mission-control">
        <div class="astronaut-info">
          <img :src="getFormattedAvatarUrl" :alt="user?.name || 'Phi hành gia'" class="astronaut-avatar">
          <div class="astronaut-details">
            <h1 class="astronaut-name">{{ user?.name || 'Phi hành gia' }}</h1>
            <div class="oxygen-level">
              <i class="fas fa-lungs"></i>
              <span>Oxy: {{ totalPoints }}</span>
            </div>
          </div>
        </div>
      </header>

      <section class="deductions-list">
        <h2 class="section-title">Nhật ký mất Oxy</h2>
        <div v-if="deductions.length > 0" class="deduction-grid">
          <div v-for="deduction in deductions" :key="deduction.id" class="deduction-item">
            <div class="deduction-icon">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <div class="deduction-details">
              <h3 class="deduction-reason">{{ deduction.reason }}</h3>
              <p class="deduction-points">-{{ deduction.points_deducted }} Oxy</p>
              <p class="deduction-date">{{ formatDate(deduction.deducted_at) }}</p>
            </div>
          </div>
        </div>
        <p v-else class="no-deductions">Tuyệt vời! Bạn chưa bị mất Oxy nào cả!</p>
      </section>
    </div>

    <button @click="quayVeVuTru" class="nut-quay-ve-vu-tru">
      <i class="fas fa-rocket"></i>
      QUAY VỀ VŨ TRỤ
    </button>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useChildpoints } from '@/assets/js/useChildpoints';
import lottie from 'lottie-web';
import zonAnimation from '@/assets/json/zon1.json';

export default {
  name: 'ChildpointsView',
  setup() {
    const router = useRouter();
    const {
      user,
      deductions,
      totalPoints,
      getFormattedAvatarUrl,
      fetchDeductions
    } = useChildpoints();

    const lottieBackground = ref(null);

    const isMobileOrTablet = computed(() => {
      return window.innerWidth <= 1024;
    });

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('vi-VN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    const initLottieBackground = () => {
      if (!lottieBackground.value || !isMobileOrTablet.value) return;
      const animation = lottie.loadAnimation({
        container: lottieBackground.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: zonAnimation
      });

      const resizeBackground = () => {
        const containerAspectRatio = window.innerWidth / window.innerHeight;
        const animationAspectRatio = 1080 / 1920;  // w / h

        if (containerAspectRatio > animationAspectRatio) {
          // Container rộng hơn, scale theo chiều rộng
          const scale = window.innerWidth / 1080;
          animation.resize();
          lottieBackground.value.style.width = `${window.innerWidth}px`;
          lottieBackground.value.style.height = `${1920 * scale}px`;
        } else {
          // Container cao hơn, scale theo chiều cao
          const scale = window.innerHeight / 1920;
          animation.resize();
          lottieBackground.value.style.width = `${1080 * scale}px`;
          lottieBackground.value.style.height = `${window.innerHeight}px`;
        }
      };

      window.addEventListener('resize', resizeBackground);
      resizeBackground();
    };

    const quayVeVuTru = () => {
      router.push('/child');
    };

    onMounted(() => {
      initLottieBackground();
      fetchDeductions();
      window.addEventListener('resize', () => {
        if (isMobileOrTablet.value) {
          initLottieBackground();
        }
      });
    });

    return {
      user,
      deductions,
      totalPoints,
      getFormattedAvatarUrl,
      formatDate,
      lottieBackground,
      isMobileOrTablet,
      quayVeVuTru
    };
  }
};
</script>

<style scoped>
@import '@/assets/styles/child-task.css';

.deductions-list {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 15px;
  margin-top: 20px;
}

.deduction-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.deduction-item {
  background: rgba(255, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.deduction-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(255, 0, 0, 0.2);
}

.deduction-icon {
  font-size: 24px;
  color: #ff4d4d;
  margin-right: 15px;
}

.deduction-details {
  flex-grow: 1;
}

.deduction-reason {
  font-size: 1.1rem;
  color: #ffffff;
  margin-bottom: 5px;
}

.deduction-points {
  font-size: 1rem;
  color: #ff4d4d;
  font-weight: bold;
}

.deduction-date {
  font-size: 0.9rem;
  color: #aaa;
}

.no-deductions {
  text-align: center;
  color: #4dff4d;
  font-size: 1.2rem;
  padding: 20px;
}

@media (max-width: 768px) {
  .deduction-grid {
    grid-template-columns: 1fr;
  }

  .deduction-item {
    padding: 12px;
  }

  .deduction-reason {
    font-size: 1rem;
  }

  .deduction-points {
    font-size: 0.9rem;
  }

  .deduction-date {
    font-size: 0.8rem;
  }

  .no-deductions {
    font-size: 1rem;
    padding: 15px;
  }

  .astronaut-avatar {
    width: 40px;
    height: 40px;
  }

  .astronaut-name {
    font-size: 1rem;
  }

  .oxygen-level {
    font-size: 0.8rem;
  }

  .section-title {
    font-size: 1.1rem;
  }

  .nut-quay-ve-vu-tru {
    font-size: 0.8rem;
    padding: 8px 12px;
  }
}

@media (max-width: 480px) {
  .content-container {
    padding: 10px;
  }

  .mission-control,
  .deductions-list {
    padding: 10px;
  }

  .deduction-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .deduction-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .nut-quay-ve-vu-tru {
    top: 10px;
    right: 10px;
  }
}
</style>