const LOCAL_STORAGE_KEY = 'marsExpeditionTasks';

export const localStorageService = {
  saveTasks(tasks) {
    const data = {
      tasks,
      lastUpdated: new Date().toISOString()
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  },

  getTasks() {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (data) {
      const parsedData = JSON.parse(data);
      const lastUpdated = new Date(parsedData.lastUpdated);
      const now = new Date();
      
      // Check if it's a new day (past midnight)
      if (lastUpdated.getDate() !== now.getDate() || 
          lastUpdated.getMonth() !== now.getMonth() || 
          lastUpdated.getFullYear() !== now.getFullYear()) {
        return null; // Return null to trigger a reset
      }
      
      return parsedData.tasks;
    }
    return null;
  },

  clearTasks() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
};