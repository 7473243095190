import { Howl } from 'howler';
import { supabase } from '@/supabase';

class AudioServiceHome {
  constructor() {
    this.dailyAudio = null;
    this.cachedDailyUrl = null;
    this.lastCheckedDate = null;
    this.isInitialized = false;
  }

  init() {
    if (this.isInitialized) return;
    this.isInitialized = true;
    console.log('AudioServiceHome initialized');
  }

  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  async getDailyAudioUrl() {
    const today = new Date();
    const dateString = this.formatDate(today);
    const fileName = `${dateString}.mp3`;

    if (this.cachedDailyUrl && this.lastCheckedDate === dateString) {
      return this.cachedDailyUrl;
    }

    try {
      const { data, error } = await supabase
        .storage
        .from('HUY')
        .getPublicUrl(`sum/${fileName}`);

      if (error) throw error;

      // Kiểm tra xem file có tồn tại không
      const response = await fetch(data.publicUrl, { method: 'HEAD' });
      if (!response.ok) {
        throw new Error('Audio file not found');
      }

      this.cachedDailyUrl = data.publicUrl;
      this.lastCheckedDate = dateString;
      return this.cachedDailyUrl;
    } catch (error) {
      console.error('Error fetching daily audio file:', error);
      // Trả về URL của file âm thanh mặc định
      return 'https://oiaglhvfveguatjytexg.supabase.co/storage/v1/object/public/HUY/sum/default.mp3';
    }
  }

  async playDailyAudio() {
    if (!this.isInitialized) {
      console.warn('AudioServiceHome not initialized. Call init() first.');
      return;
    }

    try {
      const audioUrl = await this.getDailyAudioUrl();
      
      if (this.dailyAudio) {
        this.dailyAudio.unload();
      }

      this.dailyAudio = new Howl({
        src: [audioUrl],
        html5: true,
        onload: () => console.log('Daily audio loaded successfully'),
        onloaderror: (id, error) => {
          console.error('Error loading daily audio:', error);
          // Thử lại với file mặc định nếu file chính không tải được
          if (audioUrl !== 'https://oiaglhvfveguatjytexg.supabase.co/storage/v1/object/public/HUY/sum/default.mp3') {
            this.playDefaultAudio();
          }
        }
      });

      this.dailyAudio.play();
    } catch (error) {
      console.error('Error playing daily audio:', error);
      this.playDefaultAudio();
    }
  }

  playDefaultAudio() {
    const defaultAudioUrl = 'https://oiaglhvfveguatjytexg.supabase.co/storage/v1/object/public/HUY/sum/default.mp3';
    this.dailyAudio = new Howl({
      src: [defaultAudioUrl],
      html5: true,
      onload: () => console.log('Default audio loaded successfully'),
      onloaderror: (id, error) => console.error('Error loading default audio:', error)
    });
    this.dailyAudio.play();
  }
}

export const audioServiceHome = new AudioServiceHome();