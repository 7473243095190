<template>
  <div class="p-6 bg-white rounded-lg shadow-lg">
    <h3 class="text-2xl font-semibold mb-4">Quản lý Phần thưởng</h3>

    <!-- Form thêm phần thưởng mới -->
    <form @submit.prevent="addReward" class="mb-6">
      <div class="mb-4">
        <label class="block text-gray-700">Tên phần thưởng</label>
        <input v-model="newReward.reward_name" type="text" class="mt-2 p-2 w-full border rounded" placeholder="Nhập tên phần thưởng" required>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700">Điểm cần thiết</label>
        <input v-model="newReward.points_required" type="number" class="mt-2 p-2 w-full border rounded" placeholder="Nhập điểm cần thiết" required>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700">Danh mục</label>
        <input v-model="newReward.category" type="text" class="mt-2 p-2 w-full border rounded" placeholder="Nhập danh mục" required>
      </div>

      <button type="submit" class="bg-primary text-white px-4 py-2 rounded">Thêm phần thưởng</button>
    </form>

    <!-- Cảnh báo thêm thành công hoặc thất bại -->
    <div v-if="successMessage" class="bg-green-200 text-green-800 p-2 rounded mb-4">
      {{ successMessage }}
    </div>
    <div v-if="errorMessage" class="bg-red-200 text-red-800 p-2 rounded mb-4">
      {{ errorMessage }}
    </div>

    <!-- Danh sách phần thưởng hiện tại -->
    <div class="mt-6">
      <h4 class="text-xl font-medium mb-4">Danh sách phần thưởng</h4>
      <table class="min-w-full bg-white">
        <thead class="bg-gray-200 text-gray-600">
          <tr>
            <th class="py-3 px-6 text-left">Tên</th>
            <th class="py-3 px-6 text-left">Điểm</th>
            <th class="py-3 px-6 text-left">Danh mục</th>
            <th class="py-3 px-6 text-center">Hành động</th>
          </tr>
        </thead>
        <tbody class="text-gray-600">
          <tr v-for="reward in rewards" :key="reward.id" class="border-b border-gray-200 hover:bg-gray-100">
            <td class="py-3 px-6">{{ reward.reward_name }}</td>
            <td class="py-3 px-6">{{ reward.points_required }}</td>
            <td class="py-3 px-6">{{ reward.category }}</td>
            <td class="py-3 px-6 text-center">
              <button @click="deleteReward(reward.id)" class="text-red-500 hover:text-red-700">
                <i class="fas fa-trash-alt"></i> Xóa
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Lịch sử nhận thưởng -->
    <div class="mt-8">
      <h4 class="text-xl font-medium mb-4">Lịch sử Nhận Thưởng</h4>
      <table class="min-w-full bg-white">
        <thead class="bg-gray-200 text-gray-600">
          <tr>
            <th class="py-3 px-6 text-left">User</th>
            <th class="py-3 px-6 text-left">Tên Phần thưởng</th>
            <th class="py-3 px-6 text-left">Ngày Nhận</th>
          </tr>
        </thead>
        <tbody class="text-gray-600">
          <tr v-for="history in rewardHistory" :key="history.id" class="border-b border-gray-200 hover:bg-gray-100">
            <td class="py-3 px-6">{{ history.profiles.username }}</td>
            <td class="py-3 px-6">{{ history.rewards.reward_name }}</td>
            <td class="py-3 px-6">{{ history.redeemed_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { supabase } from '@/supabase';

export default {
  name: 'ManageRewards',
  setup() {
    const rewards = ref([]);
    const rewardHistory = ref([]);
    const newReward = ref({
      reward_name: '',
      points_required: '',
      category: ''
    });
    const successMessage = ref('');
    const errorMessage = ref('');

    const fetchRewards = async () => {
      const { data, error } = await supabase
        .from('rewards')
        .select('*');
      if (error) {
        console.error('Error fetching rewards:', error);
      }
      rewards.value = data || [];
    };

    const fetchRewardHistory = async () => {
      const { data, error } = await supabase
        .from('user_rewards')
        .select(`
          id, 
          redeemed_at, 
          profiles(username), 
          rewards(reward_name)
        `)
        .order('redeemed_at', { ascending: false });

      if (error) {
        console.error('Error fetching reward history:', error);
      } else {
        rewardHistory.value = data || [];
      }
    };

    const addReward = async () => {
      successMessage.value = '';
      errorMessage.value = '';

      try {
        const { error } = await supabase
          .from('rewards')
          .insert([
            {
              reward_name: newReward.value.reward_name,
              points_required: newReward.value.points_required,
              category: newReward.value.category
            }
          ]);

        if (error) {
          errorMessage.value = 'Lỗi khi thêm phần thưởng: ' + error.message;
          console.error('Error adding reward:', error.message);
        } else {
          successMessage.value = 'Phần thưởng đã được thêm thành công!';
          fetchRewards(); // Cập nhật danh sách phần thưởng sau khi thêm thành công
          newReward.value.reward_name = '';
          newReward.value.points_required = '';
          newReward.value.category = '';
        }
      } catch (error) {
        errorMessage.value = 'Đã xảy ra lỗi khi thêm phần thưởng.';
        console.error('Error adding reward:', error);
      }
    };

    const deleteReward = async (rewardId) => {
      const { error } = await supabase
        .from('rewards')
        .delete()
        .eq('id', rewardId);

      if (error) {
        console.error('Error deleting reward:', error);
      } else {
        fetchRewards(); // Cập nhật danh sách phần thưởng sau khi xóa thành công
      }
    };

    onMounted(() => {
      fetchRewards();
      fetchRewardHistory();
    });

    return {
      rewards,
      rewardHistory,
      newReward,
      successMessage,
      errorMessage,
      addReward,
      deleteReward
    };
  }
};
</script>

<style scoped>
.bg-primary {
  background-color: #174c9a;
}
</style>
