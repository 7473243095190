import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import lottie from 'lottie-web';

gsap.registerPlugin(MotionPathPlugin);

const STAR_COUNT = 200;
const METEOR_COUNT = 20;
const METEOR_DELAY = 300;

const planets = [
  { name: 'Sao Thủy', file: require('@/assets/json/planet1.json'), size: '7vmin', orbitRadius: 0.5, orbitSpeed: 0.8 },
  { name: 'Trái đất', file: require('@/assets/json/planet2.json'), size: '9vmin', orbitRadius: 0.65, orbitSpeed: 0.6 },
  { name: 'Hành tinh chết', file: require('@/assets/json/planet3.json'), size: '11vmin', orbitRadius: 0.8, orbitSpeed: 0.4 },
  { name: 'Sao Hỏa', file: require('@/assets/json/planet4.json'), size: '8vmin', orbitRadius: 0.95, orbitSpeed: 0.2 }
];

let centerX, centerY, baseRadius;

function updateDimensions() {
  const galaxy = document.getElementById('galaxy');
  if (galaxy) {
    centerX = galaxy.offsetWidth / 2;
    centerY = galaxy.offsetHeight / 2;
    baseRadius = Math.min(centerX, centerY) * 0.8;
  }
}

export function createStars() {
  const galaxy = document.getElementById('galaxy');
  const fragment = document.createDocumentFragment();

  for (let i = 0; i < STAR_COUNT; i++) {
    const star = document.createElement('div');
    star.className = 'star';
    star.style.cssText = `
      width: ${Math.random() * 2}px;
      height: ${Math.random() * 2}px;
      left: ${Math.random() * 100}%;
      top: ${Math.random() * 100}%;
      animation-delay: ${Math.random() * 3}s;
      animation-duration: ${3 + Math.random() * 4}s;
    `;
    fragment.appendChild(star);
  }

  galaxy.appendChild(fragment);
}

function createPlanetElements(planet) {
  const orbitEl = document.createElement('div');
  orbitEl.className = 'orbit';

  const planetWrapper = document.createElement('div');
  planetWrapper.className = 'planet-wrapper';
  planetWrapper.style.width = planet.size;
  planetWrapper.style.height = planet.size;

  const planetEl = document.createElement('div');
  planetEl.className = 'planet';
  planetEl.style.width = '100%';
  planetEl.style.height = '100%';

  lottie.loadAnimation({
    container: planetEl,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: planet.file
  });

  const nameEl = document.createElement('div');
  nameEl.className = 'planet-name';
  nameEl.textContent = planet.name;

  planetWrapper.appendChild(planetEl);
  planetWrapper.appendChild(nameEl);

  const orbitPath = document.createElementNS("http://www.w3.org/2000/svg", "path");

  return { orbitEl, planetWrapper, orbitPath };
}

export function createPlanets(visitPlanet) {
  const galaxy = document.getElementById('galaxy');

  updateDimensions();
  window.addEventListener('resize', updateDimensions);

  planets.forEach(planet => {
    const { orbitEl, planetWrapper, orbitPath } = createPlanetElements(planet);
    galaxy.appendChild(orbitEl);
    galaxy.appendChild(planetWrapper);
    galaxy.appendChild(orbitPath);

    planetWrapper.addEventListener('click', (event) => {
      event.stopPropagation();
      console.log(`Clicked on planet: ${planet.name}`);
      visitPlanet(planet.name);
    });

    setupPlanetHoverEffect(planetWrapper);

    function updateOrbit() {
      const orbitRadius = baseRadius * planet.orbitRadius;
      setupOrbitPath(orbitEl, orbitPath, orbitRadius, centerX, centerY);
      animatePlanet(planetWrapper, orbitPath, planet.orbitSpeed);
    }

    updateOrbit();
    window.addEventListener('resize', updateOrbit);
  });
}

function setupPlanetHoverEffect(planetWrapper) {
  planetWrapper.addEventListener('mouseenter', () => {
    gsap.to(planetWrapper, { scale: 1.1, duration: 0.3 });
    planetWrapper.style.cursor = 'pointer';
  });
  planetWrapper.addEventListener('mouseleave', () => gsap.to(planetWrapper, { scale: 1, duration: 0.3 }));
}

function setupOrbitPath(orbitEl, orbitPath, radius, centerX, centerY) {
  orbitEl.style.width = `${radius * 2}px`;
  orbitEl.style.height = `${radius * 2}px`;

  orbitPath.setAttribute("d", `M ${centerX - radius},${centerY} a ${radius},${radius} 0 1,0 ${radius * 2},0 a ${radius},${radius} 0 1,0 -${radius * 2},0`);
  orbitPath.style.fill = "none";
  orbitPath.style.stroke = "rgba(255, 255, 255, 0.2)";
  orbitPath.style.strokeWidth = "3px";
  orbitPath.style.pointerEvents = "none";
}

function animatePlanet(planetWrapper, orbitPath, speed) {
  gsap.to(planetWrapper, {
    duration: 10 / speed,
    repeat: -1,
    ease: 'none',
    motionPath: {
      path: orbitPath,
      align: orbitPath,
      alignOrigin: [0.5, 0.5],
      autoRotate: true
    }
  });
}

export function animateFlyingSpaceship(onSpaceshipClick) {
  const spaceship = document.getElementById('flying-spaceship');

  lottie.loadAnimation({
    container: spaceship,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: require('@/assets/json/ship3.json')
  });

  function flyToRandomPosition() {
    const maxX = window.innerWidth - 50;
    const maxY = window.innerHeight - 50;
    const targetX = Math.random() * maxX;
    const targetY = Math.random() * maxY;

    gsap.to(spaceship, {
      left: targetX,
      top: targetY,
      duration: 5 + Math.random() * 5,
      ease: 'power1.inOut',
      onComplete: flyToRandomPosition
    });

    gsap.to(spaceship, {
      rotation: '+=10',
      yoyo: true,
      repeat: -1,
      duration: 1,
      ease: 'sine.inOut'
    });
  }

  flyToRandomPosition();

  spaceship.addEventListener('click', (event) => {
    event.stopPropagation();
    console.log('Clicked on flying spaceship');
    onSpaceshipClick();
  });

  spaceship.addEventListener('mouseenter', () => {
    gsap.to(spaceship, { scale: 1.1, duration: 0.3 });
    spaceship.style.cursor = 'pointer';
  });
  spaceship.addEventListener('mouseleave', () => {
    gsap.to(spaceship, { scale: 1, duration: 0.3 });
  });
}

export function createSpaceship() {
  const spaceship = document.getElementById('spaceship');
  lottie.loadAnimation({
    container: spaceship,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: require('@/assets/json/ship3.json')
  });
}

export function meteorShower() {
  for (let i = 0; i < METEOR_COUNT; i++) {
    setTimeout(createMeteor, i * METEOR_DELAY);
  }
}

function createMeteor() {
  const meteor = document.createElement('div');
  meteor.className = 'meteor';
  document.body.appendChild(meteor);

  const startX = Math.random() * window.innerWidth;
  const endX = startX - 500;
  const duration = 1 + Math.random() * 2;

  gsap.fromTo(meteor,
    { x: startX, y: -100, opacity: 1 },
    {
      x: endX,
      y: window.innerHeight + 100,
      opacity: 0,
      duration: duration,
      ease: 'none',
      onComplete: () => meteor.remove()
    }
  );
}

export function cleanup() {
  window.removeEventListener('resize', updateDimensions);
  
  gsap.killTweensOf("*");
  
  const galaxy = document.getElementById('galaxy');
  if (galaxy) {
    galaxy.innerHTML = '';
  }
  
  const meteors = document.querySelectorAll('.meteor');
  meteors.forEach(meteor => meteor.remove());
  
  lottie.destroy();
}