<template>
  <div class="p-6 bg-white rounded-lg shadow-lg">
    <h3 class="text-2xl font-semibold mb-4">Trừ Điểm</h3>

    <!-- Form trừ điểm -->
    <form @submit.prevent="deductPoints" class="mb-6">
      <div class="mb-4">
        <label class="block text-gray-700">Chọn người dùng</label>
        <select v-model="selectedProfileId" @change="updateSelectedProfile" class="mt-2 p-2 w-full border rounded" required>
          <option value="" disabled>Chọn một người dùng</option>
          <option v-for="profile in profiles" :key="profile.id" :value="profile.id">
            {{ profile.name }} - Tổng điểm: {{ profile.total_points }}
          </option>
        </select>
      </div>

      <!-- Hiển thị tên người dùng và tổng điểm khi đã chọn -->
      <div v-if="selectedProfileId" class="mb-4">
        <p class="text-gray-700">Người dùng: <strong>{{ selectedProfileName }}</strong></p>
        <p class="text-gray-700">Tổng điểm: <strong>{{ selectedProfilePoints }}</strong></p>
      </div>

      <div class="mb-4">
        <label class="block text-gray-700">Số điểm cần trừ</label>
        <input v-model="pointsToDeduct" type="number" class="mt-2 p-2 w-full border rounded" placeholder="Nhập số điểm cần trừ" required>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700">Lý do trừ điểm</label>
        <textarea v-model="deductionReason" class="mt-2 p-2 w-full border rounded" placeholder="Nhập lý do trừ điểm" required></textarea>
      </div>

      <button type="submit" class="bg-primary text-white px-4 py-2 rounded">Trừ Điểm</button>
    </form>

    <!-- Cảnh báo trừ điểm thành công hoặc thất bại -->
    <div v-if="successMessage" class="bg-green-200 text-green-800 p-2 rounded mb-4">
      {{ successMessage }}
    </div>
    <div v-if="errorMessage" class="bg-red-200 text-red-800 p-2 rounded mb-4">
      {{ errorMessage }}
    </div>

    <!-- Lịch sử trừ điểm -->
    <div class="mt-8">
      <h4 class="text-xl font-medium mb-4">Lịch sử trừ điểm</h4>
      <div v-if="deductionHistory.length === 0" class="text-gray-600">Chưa có lịch sử trừ điểm.</div>
      <div v-else class="bg-white shadow-lg rounded-lg overflow-hidden">
        <table class="min-w-full bg-white">
          <thead class="bg-gray-200 text-gray-600">
            <tr>
              <th class="py-3 px-6 text-left">Thời gian</th>
              <th class="py-3 px-6 text-left">Số điểm</th>
              <th class="py-3 px-6 text-left">Lý do</th>
            </tr>
          </thead>
          <tbody class="text-gray-600">
            <tr v-for="deduction in deductionHistory" :key="deduction.id" class="border-b border-gray-200 hover:bg-gray-100">
              <td class="py-3 px-6">{{ deduction.deducted_at }}</td>
              <td class="py-3 px-6">{{ deduction.points_deducted }}</td>
              <td class="py-3 px-6">{{ deduction.reason }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '@/supabase'

export default {
  name: 'DeductPoints',
  setup() {
    const profiles = ref([])
    const selectedProfileId = ref('')
    const selectedProfileName = ref('')
    const selectedProfilePoints = ref('')
    const pointsToDeduct = ref('')
    const deductionReason = ref('')
    const successMessage = ref('')
    const errorMessage = ref('')
    const deductionHistory = ref([])

    // Fetch profiles for the dropdown
    const fetchProfiles = async () => {
      const { data, error } = await supabase
        .from('profiles')
        .select('id, name, total_points')  // Fetch name and total_points along with profiles

      if (error) {
        console.error('Error fetching profiles:', error.message)
      } else {
        profiles.value = data || []
      }
    }

    // Update selected profile details when the user selects a profile
    const updateSelectedProfile = () => {
      const selectedProfile = profiles.value.find(profile => profile.id === selectedProfileId.value)
      if (selectedProfile) {
        selectedProfileName.value = selectedProfile.name
        selectedProfilePoints.value = selectedProfile.total_points
      }
    }

    // Fetch deduction history
    const fetchDeductionHistory = async () => {
      const { data, error } = await supabase
        .from('deductions')
        .select('*')
        .order('deducted_at', { ascending: false })

      if (error) {
        console.error('Error fetching deduction history:', error.message)
      } else {
        deductionHistory.value = data || []
      }
    }

    // Deduct points from the selected profile
    const deductPoints = async () => {
      successMessage.value = ''
      errorMessage.value = ''

      if (!selectedProfileId.value) {
        errorMessage.value = 'Vui lòng chọn người dùng';
        return;
      }

      try {
        const remainingPoints = selectedProfilePoints.value - pointsToDeduct.value;

        if (remainingPoints < 0) {
          errorMessage.value = 'Không đủ điểm để trừ';
          return;
        }

        const { error } = await supabase
          .from('deductions')
          .insert([{
            user_id: selectedProfileId.value,
            points_deducted: pointsToDeduct.value,
            reason: deductionReason.value,
            deducted_at: new Date().toISOString()
          }]);

        if (error) {
          errorMessage.value = 'Lỗi khi trừ điểm: ' + error.message;
          console.error('Error deducting points:', error.message);
        } else {
          // Update total_points in profiles table
          await supabase
            .from('profiles')
            .update({ total_points: remainingPoints })
            .eq('id', selectedProfileId.value);

          successMessage.value = 'Trừ điểm thành công!';
          fetchDeductionHistory();
          pointsToDeduct.value = '';
          deductionReason.value = '';
          selectedProfileId.value = '';
          selectedProfileName.value = '';
          selectedProfilePoints.value = '';
        }
      } catch (error) {
        errorMessage.value = 'Đã xảy ra lỗi khi trừ điểm.';
        console.error('Error deducting points:', error);
      }
    }

    onMounted(() => {
      fetchProfiles();  // Lấy danh sách profiles khi component được gắn
      fetchDeductionHistory();  // Lấy lịch sử trừ điểm khi component được gắn
    })

    return {
      profiles,
      selectedProfileId,
      selectedProfileName,
      selectedProfilePoints,
      pointsToDeduct,
      deductionReason,
      successMessage,
      errorMessage,
      deductionHistory,
      deductPoints,
      updateSelectedProfile
    }
  }
}
</script>

<style scoped>
.bg-primary {
  background-color: #174c9a;
}
</style>
