import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import LoginView from '../components/Auth/LoginView.vue'
import ChildHomeView from '../views/ChildHomeView.vue'
import ChildTaskView from '../views/ChildTaskView.vue'
import Childpoints from '../views/Childpoints.vue'  // Thêm import cho Childpoints
import AdminHomeView from '../views/AdminHomeView.vue'
import ManageTasks from '../components/Admin/ManageTasks.vue'
import ManageRewards from '../components/Admin/ManageRewards.vue'
import ProgressView from '../components/Admin/ProgressView.vue'
import DeductPoints from '../components/Admin/DeductPoints.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/child',
    name: 'ChildHome',
    component: ChildHomeView,
    meta: { requiresAuth: true, role: 'child' }
  },
  {
    path: '/tasks',
    name: 'ChildTasks',
    component: ChildTaskView,
    meta: { requiresAuth: true, role: 'child' }
  },
  {
    path: '/childpoints',  // Thêm route mới cho Childpoints
    name: 'Childpoints',
    component: Childpoints,
    meta: { requiresAuth: true, role: 'child' }
  },
  {
    path: '/admin',
    name: 'AdminHome',
    component: AdminHomeView,
    meta: { requiresAuth: true, role: 'admin' },
    children: [
      {
        path: 'tasks',
        name: 'ManageTasks',
        component: ManageTasks
      },
      {
        path: 'rewards',
        name: 'ManageRewards',
        component: ManageRewards
      },
      {
        path: 'progress',
        name: 'ProgressView',
        component: ProgressView
      },
      {
        path: 'deduct-points',
        name: 'DeductPoints',
        component: DeductPoints
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiredRole = to.meta.role

  if (requiresAuth && !store.getters.isLoggedIn) {
    next('/')
  } else if (requiresAuth && requiredRole && store.getters.userRole !== requiredRole) {
    next('/')
  } else {
    next()
  }
})

export default router