<template>
  <div class="child-home-view">
    <div id="galaxy">
      <div id="sun"></div>
    </div>
    <div id="flying-spaceship"></div>
    <div id="top-bar">
      <div id="energyBar">
        <div id="energyFill"></div>
        <div id="energyGlow"></div>
        <div id="energyText">Oxy: {{ currentPoints }}</div>
      </div>
    </div>
    <div v-if="showCelebration" id="celebration">🎉 Chúc mừng! Bạn đã hoàn thành tất cả nhiệm vụ! 🎉</div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { createStars, createPlanets, animateFlyingSpaceship, createSpaceship, meteorShower, cleanup } from '@/assets/js/child-home-logic';
import { audioServiceHome } from '@/assets/js/audioServiceHome';

gsap.registerPlugin(MotionPathPlugin);

export default {
  name: 'ChildHomeView',
  setup() {
    const router = useRouter();
    const store = useStore();
    const currentMission = ref('Khám phá vũ trụ');
    const showCelebration = ref(false);
    let meteorInterval;

    const maxPoints = ref(100); // Giá trị mặc định của thanh năng lượng
    const currentPoints = computed(() => store.state.totalPoints);

    const initAudioAndPlayDailySummary = async () => {
      try {
        audioServiceHome.init();
        await audioServiceHome.playDailyAudio();
      } catch (error) {
        console.error('Error playing daily audio:', error);
        // Có thể hiển thị thông báo lỗi cho người dùng ở đây
      }
    };

    onMounted(async () => {
      await store.dispatch('fetchUserData');
      initApp();
      updateEnergyBar();
    });

    onUnmounted(() => {
      clearInterval(meteorInterval);
      cleanup();
    });

    function initApp() {
      createStars();
      createPlanets(visitPlanet);
      animateFlyingSpaceship(initAudioAndPlayDailySummary);
      createSpaceship();

      gsap.to('#sun', {
        scale: 1.1,
        duration: 2,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut'
      });

      meteorInterval = setInterval(meteorShower, 30000);

      gsap.to("#energyBar", {
        scale: 1.02,
        duration: 0.8,
        repeat: -1,
        yoyo: true,
        ease: "sine.inOut"
      });
    }

    function visitPlanet(planetName) {
      if (planetName === 'Hành tinh chết') {
        router.push({ name: 'Childpoints' });
      } else {
        store.commit('SET_SELECTED_PLANET', planetName);
        router.push({ name: 'ChildTasks' });
      }
    }

    function updateEnergyBar() {
      const fillWidth = (currentPoints.value / maxPoints.value) * 100;
      gsap.to("#energyFill", {
        width: `${fillWidth}%`,
        duration: 0.5,
        ease: "power2.out"
      });
      gsap.to("#energyGlow", {
        x: `${fillWidth - 100}%`,
        duration: 0.5,
        ease: "power2.out"
      });
    }

    return {
      maxPoints,
      currentPoints,
      currentMission,
      showCelebration,
      updateEnergyBar
    };
  }
};
</script>

<style scoped>
@import '@/assets/styles/child-home.css';

#top-bar {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

#energyBar {
  width: 200px;
  height: 30px;
  background-color: rgba(15, 15, 27, 0.7);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

#energyFill {
  width: 0%;
  height: 100%;
  background: linear-gradient(90deg, #00ffff, #00ff00);
  border-radius: 15px;
  position: relative;
}

#energyGlow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(0, 255, 255, 0.5), transparent);
  filter: blur(5px);
}

#energyText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.8);
}

#flying-spaceship {
  cursor: pointer;
  position: absolute;
  width: 100px;
  height: 100px;
  pointer-events: all;
  z-index: 1000;
}

#celebration {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  font-size: 24px;
  text-align: center;
  z-index: 2000;
}
</style>