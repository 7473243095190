import { Howl } from 'howler';

class AudioService {
  constructor() {
    this.sounds = {};
    this.isInitialized = false;
  }

  init() {
    if (this.isInitialized) return;

    // Khởi tạo các âm thanh
    this.sounds = {
      taskComplete: new Howl({ src: [require('@/assets/sounds/win.mp3')] }),
      rewardClaimed: new Howl({ src: [require('@/assets/sounds/reward.mp3')] }),
      allTasksComplete: new Howl({ src: [require('@/assets/sounds/youwin.mp3')] })
    };

    this.isInitialized = true;
  }

  playSound(soundName) {
    if (!this.isInitialized) {
      console.warn('Audio Service not initialized. Call init() first.');
      return;
    }

    const sound = this.sounds[soundName];
    if (sound) {
      sound.play();
    } else {
      console.warn(`Sound "${soundName}" not found.`);
    }
  }
}

export const audioService = new AudioService();