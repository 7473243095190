// src/supabase.js
import { createClient } from '@supabase/supabase-js'

// Thay thế bằng URL Supabase của bạn
const supabaseUrl = 'https://oiaglhvfveguatjytexg.supabase.co'

// Thay thế bằng Anon Key của bạn
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9pYWdsaHZmdmVndWF0anl0ZXhnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDY5NzMwOTMsImV4cCI6MjAyMjU0OTA5M30.AQ-rVuHYawErn7Vmlzz9UU_pa2nKGAGAu53QUFQxB-c'

// Tạo client Supabase
export const supabase = createClient(supabaseUrl, supabaseAnonKey)
