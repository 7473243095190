import { ref, onMounted, computed, watch } from 'vue';
import { supabase } from '@/supabase';
import { useStore } from 'vuex';

export function useChildTask() {
  const user = ref(null);
  const tasks = ref([]);
  const rewards = ref([]);
  const rewardHistory = ref([]);
  const totalPoints = ref(0);
  const avatarUrl = ref('');
  const store = useStore();

  const fetchUserData = async () => {
    console.log('Fetching user data');
    const currentUser = store.state.user;
    if (currentUser) {
      user.value = currentUser;
      const { data, error } = await supabase
        .from('profiles')
        .select('total_points, avatar_url, name')
        .eq('id', currentUser.id)
        .single();

      if (error) {
        console.error('Error fetching user data:', error);
        return;
      }

      console.log('User data fetched:', data);
      totalPoints.value = data.total_points;
      avatarUrl.value = data.avatar_url;
      user.value = { ...currentUser, name: data.name };

      // Fetch related data after successfully fetching the user data
      await fetchTasks();
      await fetchRewards();
      await fetchRewardHistory();
    }
  };

  const fetchTasks = async () => {
    if (!user.value) {
      console.log('No user found, skipping fetchTasks');
      return;
    }
    console.log('Fetching tasks for user:', user.value.id);
    const { data, error } = await supabase
      .from('user_tasks')
      .select(`
        id,
        task_id,
        assigned_at,
        is_completed,
        last_reset_date,
        tasks (task_name, points, category, repeat_type)
      `)
      .eq('user_id', user.value.id);

    if (error) {
      console.error('Error fetching tasks:', error);
    } else {
      console.log('Tasks fetched successfully:', data);
      tasks.value = data.map(({ tasks: taskData, ...rest }) => ({
        id: rest.id,
        ...taskData,
        assigned_at: rest.assigned_at,
        is_completed: rest.is_completed,
        last_reset_date: rest.last_reset_date
      }));
      console.log('Processed tasks:', tasks.value);
    }
  };

  const completeTask = async (taskId) => {
    console.log('Completing task:', taskId);
    const { data: userTaskData, error: userTaskError } = await supabase
      .from('user_tasks')
      .select('task_id, user_id, is_completed')
      .eq('id', taskId)
      .single();

    if (userTaskError || !userTaskData) {
      console.error('Error fetching task data:', userTaskError ? userTaskError.message : 'No data found');
      return;
    }

    const actualTaskId = userTaskData.task_id;
    const userId = userTaskData.user_id;
    const isCompleted = userTaskData.is_completed;

    if (isCompleted) {
      console.log('Task already completed. No points will be added.');
      return;
    }

    console.log('Fetched task_id from user_tasks:', actualTaskId);

    if (!actualTaskId) {
      console.error('Task ID is undefined after fetching from user_tasks:', userTaskData);
      return;
    }

    const today = new Date().toISOString().split('T')[0];

    try {
      const { data: taskData, error: taskError } = await supabase
        .from('tasks')
        .select('points')
        .eq('id', actualTaskId)
        .single();

      if (taskError || !taskData) {
        throw new Error('Error fetching task points: ' + taskError.message);
      }

      const taskPoints = taskData.points;

      const { error: updateError } = await supabase
        .from('user_tasks')
        .update({ is_completed: true, last_reset_date: today })
        .eq('id', taskId);

      if (updateError) {
        throw new Error('Error updating task completion: ' + updateError.message);
      }

      const { error: insertError } = await supabase
        .from('completions')
        .insert({
          profile_id: userId,
          task_id: actualTaskId,
          points: taskPoints,
          completion_date: new Date().toISOString()
        });

      if (insertError) {
        throw new Error('Error inserting completion: ' + insertError.message);
      }

      totalPoints.value += taskPoints;
      const { error: pointsUpdateError } = await supabase
        .from('profiles')
        .update({ total_points: totalPoints.value })
        .eq('id', user.value.id);

      if (pointsUpdateError) {
        throw new Error('Error updating total points: ' + pointsUpdateError.message);
      }

      await store.dispatch('fetchUserData');

      tasks.value = tasks.value.map(t =>
        t.id === taskId ? { ...t, is_completed: true } : t
      );

      console.log('Task completed and points updated successfully');

    } catch (error) {
      console.error('Complete Task Error:', error);
    }
  };

  const fetchRewards = async () => {
    console.log('Fetching rewards');
    const { data, error } = await supabase
      .from('rewards')
      .select('*')
      .order('points_required', { ascending: true });

    if (error) {
      console.error('Error fetching rewards:', error);
    } else {
      console.log('Rewards fetched successfully:', data);
      rewards.value = data;
    }
  };

  const fetchRewardHistory = async () => {
    if (!user.value) {
      console.log('No user found, skipping fetchRewardHistory');
      return;
    }
    console.log('Fetching reward history for user:', user.value.id);
    const { data, error } = await supabase
      .from('user_rewards')
      .select(`
        id,
        redeemed_at,
        rewards (reward_name)
      `)
      .eq('user_id', user.value.id)
      .order('redeemed_at', { ascending: false });

    if (error) {
      console.error('Error fetching reward history:', error);
    } else {
      console.log('Reward history fetched successfully:', data);
      rewardHistory.value = data.map(({ rewards, ...rest }) => ({
        ...rest,
        reward_name: rewards.reward_name
      }));
    }
  };

  const redeemReward = async (rewardId) => {
    console.log('Redeeming reward:', rewardId);
    const reward = rewards.value.find(r => r.id === rewardId);
    if (!reward || totalPoints.value < reward.points_required) {
      console.log('Cannot redeem reward: insufficient points or reward not found');
      return;
    }

    const { error } = await supabase
      .from('user_rewards')
      .insert({
        user_id: user.value.id,
        reward_id: rewardId,
        redeemed_at: new Date().toISOString()
      });

    if (error) {
      console.error('Error redeeming reward:', error);
    } else {
      console.log('Reward redeemed successfully');
      totalPoints.value -= reward.points_required;
      await supabase
        .from('profiles')
        .update({ total_points: totalPoints.value })
        .eq('id', user.value.id);

      await fetchRewardHistory();
    }
  };

  const availableRewards = computed(() => {
    return rewards.value.filter(reward => reward.points_required <= totalPoints.value);
  });

  const getFormattedAvatarUrl = computed(() => {
    if (avatarUrl.value) {
      if (!avatarUrl.value.includes('/preview')) {
        return `${avatarUrl.value}-/preview/300x300/-/format/auto/-/quality/smart_retina/-/progressive/yes/-/round_corner/20/`;
      }
      return avatarUrl.value;
    }
    return 'https://via.placeholder.com/300x300?text=Avatar';
  });

  onMounted(fetchUserData);

  watch(() => store.state.user, () => {
    console.log('User state changed, fetching user data');
    fetchUserData();
  });

  return {
    user,
    tasks,
    rewards,
    rewardHistory,
    totalPoints,
    availableRewards,
    completeTask,
    redeemReward,
    getFormattedAvatarUrl,
    fetchUserData,
    fetchTasks
  };
}