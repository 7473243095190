<template>
  <div class="p-6 bg-white rounded-lg shadow-lg">
    <h3 class="text-2xl font-semibold mb-4">Theo Dõi Tiến Độ Người Dùng</h3>

    <!-- Chọn người dùng -->
    <div class="mb-4">
      <label class="block text-gray-700">Chọn người dùng</label>
      <select v-model="selectedProfileId" @change="fetchUserProgress" class="mt-2 p-2 w-full border rounded">
        <option value="" disabled>Chọn một người dùng</option>
        <option v-for="profile in profiles" :key="profile.id" :value="profile.id">
          {{ profile.name }}
        </option>
      </select>
    </div>

    <!-- Thông tin người dùng -->
    <div v-if="userData" class="mb-6">
      <p class="text-lg"><strong>Tổng điểm hiện có:</strong> {{ userData.total_points }}</p>
    </div>

    <!-- Lý do cộng/trừ điểm -->
    <div v-if="reasonsData.length > 0" class="mb-6">
      <h4 class="text-xl font-medium mb-4">Lý do cộng/trừ điểm</h4>
      <ul>
        <li v-for="reason in reasonsData" :key="reason.id" class="border-b py-2">
          <p><strong>Loại:</strong> {{ reason.type }}</p>
          <p><strong>Điểm:</strong> {{ reason.points }}</p>
          <p><strong>Lý do:</strong> {{ reason.reason }}</p>
          <p><strong>Ngày:</strong> {{ reason.date }}</p>
        </li>
      </ul>
    </div>

    <!-- Thống kê nhiệm vụ -->
    <div v-if="taskStats" class="mb-6">
      <h4 class="text-xl font-medium mb-4">Thống Kê Nhiệm Vụ</h4>
      <div>
        <p><strong>Nhiệm vụ hoàn thành thường xuyên nhất:</strong> {{ taskStats.most_frequent_task }}</p>
        <p><strong>Nhiệm vụ yếu nhất:</strong> {{ taskStats.weakest_task }}</p>
        <p><strong>Hoàn thành trong khoảng thời gian nào:</strong> {{ taskStats.best_completion_time }}</p>
      </div>
    </div>
    
    <!-- Tỉ trọng các loại nhiệm vụ hoàn thành -->
    <div v-if="taskDistributionData.length > 0" class="mb-6">
      <h4 class="text-xl font-medium mb-4">Tỉ trọng các loại nhiệm vụ</h4>
      <ul>
        <li v-for="distribution in taskDistributionData" :key="distribution.type" class="border-b py-2">
          <p><strong>Loại:</strong> {{ distribution.type }}</p>
          <p><strong>Tỉ trọng:</strong> {{ distribution.percentage }}%</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '@/supabase'

export default {
  name: 'ProgressView',
  setup() {
    const profiles = ref([])
    const selectedProfileId = ref('')
    const userData = ref(null)
    const reasonsData = ref([])
    const taskStats = ref({})
    const taskDistributionData = ref([])

    // Fetch danh sách profiles
    const fetchProfiles = async () => {
      const { data, error } = await supabase
        .from('profiles')
        .select('id, name')

      if (error) {
        console.error('Error fetching profiles:', error.message)
      } else {
        profiles.value = data || []
      }
    }

    // Fetch thông tin người dùng khi chọn profile
    const fetchUserProgress = async () => {
      if (!selectedProfileId.value) return

      // Fetch tổng điểm của người dùng
      const { data: userDataResponse, error: userError } = await supabase
        .from('profiles')
        .select('total_points')
        .eq('id', selectedProfileId.value)
        .single()

      if (userError) {
        console.error('Error fetching user data:', userError.message)
        return
      }
      userData.value = userDataResponse

      // Fetch lý do cộng điểm
      const { data: completionsResponse, error: completionsError } = await supabase
        .from('completions')
        .select('points, \'Hoàn thành nhiệm vụ\' AS reason, completion_date AS date, \'Cộng điểm\' AS type')
        .eq('profile_id', selectedProfileId.value)

      if (completionsError) {
        console.error('Error fetching completions data:', completionsError.message)
        return
      }

      // Fetch lý do trừ điểm
      const { data: deductionsResponse, error: deductionsError } = await supabase
        .from('deductions')
        .select('points_deducted AS points, reason, deducted_at AS date, \'Trừ điểm\' AS type')
        .eq('user_id', selectedProfileId.value)

      if (deductionsError) {
        console.error('Error fetching deductions data:', deductionsError.message)
        return
      }

      // Kết hợp kết quả từ cả hai bảng
      reasonsData.value = [...completionsResponse, ...deductionsResponse]

      // Fetch thống kê nhiệm vụ
      const { data: taskStatsResponse, error: taskStatsError } = await supabase
        .rpc('calculate_task_stats', { profile_id: selectedProfileId.value })

      if (taskStatsError) {
        console.error('Error fetching task stats:', taskStatsError.message)
        return
      }
      taskStats.value = taskStatsResponse

      // Fetch tỉ trọng các loại nhiệm vụ hoàn thành
      const { data: taskDistributionResponse, error: taskDistributionError } = await supabase
        .rpc('calculate_task_distribution', { profile_id: selectedProfileId.value })

      if (taskDistributionError) {
        console.error('Error fetching task distribution data:', taskDistributionError.message)
        return
      }
      taskDistributionData.value = taskDistributionResponse
    }

    onMounted(() => {
      fetchProfiles()
    })

    return {
      profiles,
      selectedProfileId,
      userData,
      reasonsData,
      taskStats,
      taskDistributionData,
      fetchUserProgress
    }
  }
}
</script>

<style scoped>
.bg-primary {
  background-color: #174c9a;
}
</style>