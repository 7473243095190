import { createStore } from 'vuex'
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://oiaglhvfveguatjytexg.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9pYWdsaHZmdmVndWF0anl0ZXhnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDY5NzMwOTMsImV4cCI6MjAyMjU0OTA5M30.AQ-rVuHYawErn7Vmlzz9UU_pa2nKGAGAu53QUFQxB-c'
const supabase = createClient(supabaseUrl, supabaseAnonKey)

export default createStore({
  state: {
    user: null,
    tasks: [],
    rewards: [],
    userRewards: [],
    deductions: [],
    progress: [],
    selectedPlanet: null,
    totalPoints: 0
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_TASKS(state, tasks) {
      state.tasks = tasks
    },
    SET_REWARDS(state, rewards) {
      state.rewards = rewards
    },
    SET_USER_REWARDS(state, userRewards) {
      state.userRewards = userRewards
    },
    SET_DEDUCTIONS(state, deductions) {
      state.deductions = deductions
    },
    SET_PROGRESS(state, progress) {
      state.progress = progress
    },
    SET_SELECTED_PLANET(state, planetName) {
      state.selectedPlanet = planetName
    },
    SET_TOTAL_POINTS(state, points) {
      state.totalPoints = points
    }
  },
  actions: {
    async setUser({ commit, dispatch }, user) {
      commit('SET_USER', user)
      if (user) {
        localStorage.setItem('user_session', JSON.stringify(user))
        await dispatch('fetchUserData')
      } else {
        localStorage.removeItem('user_session')
      }
    },
    async login({ dispatch }, { email, password }) {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password })
      if (error) throw error
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', data.user.id)
        .single()
      if (profileError) throw profileError
      await dispatch('setUser', { ...data.user, ...profileData })
    },
    async loginWithPin({ dispatch }, { profileId, pin }) {
      const { data, error } = await supabase.rpc('verify_child_pin', { p_profile_id: profileId, p_pin: pin })
      if (error) throw error
      if (!data) throw new Error('PIN kh�ng h?p l?')
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', profileId)
        .single()
      if (profileError) throw profileError
      await dispatch('setUser', profileData)
    },
    async logout({ dispatch }) {
      await supabase.auth.signOut()
      await dispatch('setUser', null)
    },
    async fetchTasks({ commit, state }) {
      const { data, error } = await supabase
        .from('user_tasks')
        .select('*')
        .eq('user_id', state.user.id)
      if (error) throw error
      commit('SET_TASKS', data)
    },
    async fetchRewards({ commit }) {
      const { data, error } = await supabase
        .from('rewards')
        .select('*')
      if (error) throw error
      commit('SET_REWARDS', data)
    },
    async fetchUserRewards({ commit, state }) {
      const { data, error } = await supabase
        .from('user_rewards')
        .select('*, rewards(*)')
        .eq('user_id', state.user.id)
      if (error) throw error
      commit('SET_USER_REWARDS', data)
    },
    async fetchDeductions({ commit, state }) {
      const { data, error } = await supabase
        .from('deductions')
        .select('*')
        .eq('user_id', state.user.id)
      if (error) throw error
      commit('SET_DEDUCTIONS', data)
    },
    async fetchProgress({ commit, state }) {
      const { data, error } = await supabase
        .from('completions')
        .select('*')
        .eq('profile_id', state.user.id)
      if (error) throw error
      commit('SET_PROGRESS', data)
    },
    async initializeStore({ dispatch }) {
      const session = supabase.auth.session()
      if (session) {
        const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', session.user.id)
          .single()
        if (!profileError) {
          await dispatch('setUser', { ...session.user, ...profileData })
        }
      }
    },
    async fetchUserData({ commit, state }) {
      if (state.user) {
        const { data, error } = await supabase
          .from('profiles')
          .select('total_points')
          .eq('id', state.user.id)
          .single()
        
        if (error) {
          console.error('Error fetching user data:', error)
        } else {
          commit('SET_TOTAL_POINTS', data.total_points)
        }
      }
    }
  },
  getters: {
    isLoggedIn: state => !!state.user,
    userRole: state => state.user ? state.user.role : null,
    userName: state => state.user ? state.user.name : null,
    userAvatar: state => state.user ? state.user.avatar_url : null,
    totalPoints: state => state.totalPoints
  }
})